import urls from "../../global/constants/UrlConstants";
import {
  getCallParams,
  getNoAuthCallParams,
  makeCall,
} from "../../utils/service";

export const getAppPlans = async (language: string) => {
  const url = `${urls.getPlans}?lang=${language}`;
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const createSubscription = async (
  priceId: string,
  paymentMethodId: string
) => {
  const obj = {
    priceId,
    paymentMethodId,
  };
  try {
    const callParams = await getCallParams("POST", obj);
    const response = await makeCall(urls.createSubscription, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const updatePlan = async (priceId: string, paymentMethodId: string) => {
  const obj = {
    priceId,
    paymentMethodId,
  };
  try {
    const callParams = await getCallParams("POST", obj);
    const response = await makeCall(urls.updatePlan, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getSuccessDetails = async (id: string) => {
  const url = `${urls.getSuccessDetails}/${id}`;
  try {
    const callParams = await getNoAuthCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
