import urls from "../../global/constants/UrlConstants";
import { getCallParams, makeCall } from "../../utils/service";

export const getProfile = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(urls.getProfile, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getAnalysisData = async (profileId: string, language: string) => {
  const url = `${urls.analysisData}?id=${profileId}&lang=${language}`;
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getMahadashaData = async (profileId: string, language: string) => {
  const url = `${urls.mahadashaData}?id=${profileId}&lang=${language}`;
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getMineralsData = async (profileId: string, language: string) => {
  const url = `${urls.missingMinerals}?id=${profileId}&lang=${language}`;
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const makeDefault = async (profileId: string) => {
  try {
    const callParams = await getCallParams("POST", { profileId });
    const response = await makeCall(urls.makeDefault, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const setPromoCodeValue = async (promoCode: string) => {
  try {
    const callParams = await getCallParams("POST", { promoCode });
    const response = await makeCall(urls.setPromoCode, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const contactUs = async (reason: string, message: string) => {
  const obj = {
    reason,
    message,
  };
  try {
    const callParams = await getCallParams("POST", obj);
    const response = await makeCall(urls.contactUsApi, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
