import urls from "../../global/constants/UrlConstants";
import { getCallParams, makeCall } from "../../utils/service";

export const getSubscriptionDetails = async (language: string) => {
  const url = `${urls.getSubscription}?lang=${language}`;
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getInvoiceDetails = async (page: number, rowsPerPage: number) => {
  const url = `${urls.getInvoices}?page=${page}&per_page=${rowsPerPage}`;
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const updateUserProfile = async (profile: any) => {
  try {
    const callParams = await getCallParams("POST", profile);
    const response = await makeCall(urls.updateUserProfile, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const cancelSubscription = async () => {
  try {
    const callParams = await getCallParams("POST");
    const response = await makeCall(urls.cancelSubscription, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const addPaymentMethod = async (paymentMethodId: string) => {
  try {
    const callParams = await getCallParams("POST", { paymentMethodId });
    const response = await makeCall(urls.addPaymentMethods, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const makeDefaultPaymentMethod = async (paymentMethodId: string) => {
  try {
    const callParams = await getCallParams("POST", { paymentMethodId });
    const response = await makeCall(urls.makeDefaultPaymentMethod, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
