import urls from "../../global/constants/UrlConstants";
import { getCallParams, makeCall } from "../../utils/service";

export const getProfileById = async (id: string) => {
  const url = `${urls.getProfileById}?profileId=${id}`;
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const updateProfile = async (profile: any) => {
  try {
    const callParams = await getCallParams("POST", profile);
    const response = await makeCall(urls.updateProfile, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const makeDefault = async (profileId: string) => {
  try {
    const callParams = await getCallParams("POST", profileId);
    const response = await makeCall(urls.makeDefault, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
