import {
  AppBar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import AstrologyLogo from "../../../assets/icons/logo.svg";
import englishIcon from "../../../assets/icons/englishIcon.svg";
import polskiIcon from "../../../assets/icons/polskiIcon.svg";
import profileIcon from "../../../assets/icons/profileIcon.svg";
import userProfileIcon from "../../../assets/icons/userProfileIcon.svg";
import subscriptionIcon from "../../../assets/icons/subscriptionIcon.svg";
import resetPasswordIcon from "../../../assets/icons/resetPasswordIcon.svg";
import logoutIcon from "../../../assets/icons/logoutIcon.svg";
import backArrow from "../../../assets/icons/backArrow.svg";
import profilesIcon from "../../../assets/icons/profilesIcon.svg";
import { centerItemFlex, theme } from "../../../utils/styles";
import appHeaderStyles from "./AppHeader.styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import strings from "../../../global/constants/StringConstants";
import { store } from "../../../utils/store";
import {
  logOutAction,
  selectAuthenticated,
  selectFirstName,
  selectLanguage,
  setLanguageAction,
} from "../../../redux/authSlice";
import history from "../../../utils/history";
import urls from "../../../global/constants/UrlConstants";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import MenuIcon from "@mui/icons-material/Menu";
import { CustomDialog } from "../../../global/components";
import { getMoonPhases } from "./AppHeaderServices";
import { getMoonPhasesSymbols, isTruthy } from "../../../helpers/methods";
import notifiers from "../../../global/constants/NotificationConstants";
import CustomSelect from "../../../global/components/CustomSelect/CustomSelect";
import { doesPageHasComponent } from "../../../utils/AuthorizationManager";

interface CustomProps {
  toggleDrawer: Function;
  location?: any;
}

const AppHeader = (props: CustomProps) => {
  const classes = appHeaderStyles;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const xl = useMediaQuery(theme.breakpoints.down("xl"));
  const { t, i18n } = useTranslation();
  const isAuthenticated = useAppSelector(selectAuthenticated);
  const selectedLanguage1 = useAppSelector(selectLanguage);
  const user = useAppSelector(selectFirstName);
  const dispatch = useAppDispatch();
  const page = window.location.pathname.split("/")[1].toLowerCase();
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    i18n.language.toLowerCase() === "pl" ? i18n.language.toLowerCase() : "en"
  );
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [moonDetails, setMoonDetails] = useState({
    moonPhase: 0,
    nakshatra: "",
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (isAuthenticated) {
      getMoonPhasesData();
      const intervalId = setInterval(() => {
        getMoonPhasesData();
      }, 3600000);
      return () => clearInterval(intervalId);
    }
  }, [i18n.language]);

  useEffect(() => {
    if (!selectedLanguage1) {
      dispatch(
        setLanguageAction({
          language: "pl",
        })
      );
      setSelectedLanguage("pl");
      i18n.changeLanguage("pl");
    }
  }, []);

  const menuItems = [
    {
      title: `${t("profiles")}`,
      onclick: () => history.push(urls.dashboardViewPath),
      icon: profilesIcon,
    },
    {
      title: `${t("profile")}`,
      onclick: () => history.push(urls.userProfileViewPath),
      icon: userProfileIcon,
    },
    {
      title: `${t("subscription")}`,
      onclick: () => history.push(urls.subscriptionViewPath),
      icon: subscriptionIcon,
    },
    {
      title: `${t("updatePassword")}`,
      onclick: () => history.push(urls.updatePasswordViewPath),
      icon: resetPasswordIcon,
    },
    {
      title: `${t("logout")}`,
      onclick: () => setOpenDialog(true),
      icon: logoutIcon,
    },
  ];

  const menuList: any = [
    {
      header: [
        {
          value: "pl",
          content: (
            <Stack direction="row" alignItems={"center"} spacing={1.5}>
              <img src={polskiIcon} height={"35px"} alt="Module Not Found..." />
            </Stack>
          ),
          selected: true,
        },
        {
          value: "en",
          content: (
            <Stack direction="row" alignItems={"center"} spacing={1.5}>
              <img
                src={englishIcon}
                height={"35px"}
                alt="Module Not Found..."
              />
            </Stack>
          ),
          selected: false,
        },
      ],
    },
    {
      menu: [
        {
          value: "pl",
          content: (
            <Stack direction="row" alignItems={"center"} spacing={1.5}>
              <img src={polskiIcon} height={"35px"} alt="Module Not Found..." />
              <Typography sx={classes.languageTypo}>Polski</Typography>
            </Stack>
          ),
          selected: true,
        },
        {
          value: "en",
          content: (
            <Stack direction="row" alignItems={"center"} spacing={1.5}>
              {isMobile ? (
                <img
                  src={englishIcon}
                  height={"25px"}
                  alt="Module Not Found..."
                />
              ) : (
                <img
                  src={englishIcon}
                  height={"30px"}
                  alt="Module Not Found..."
                />
              )}
              <Typography sx={classes.languageTypo}>English</Typography>
            </Stack>
          ),
          selected: false,
        },
      ],
    },
  ];

  const getMoonPhasesData = async () => {
    try {
      const language = i18n.language.toLowerCase();
      const response = await getMoonPhases(language);
      setMoonDetails(response);
    } catch (error: any) {
      console.log(
        isTruthy(error.errorMessage)
          ? error.errorMessage
          : notifiers.GENERIC_ERROR
      );
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    setSelectedLanguage(event.target.value);
    i18n.changeLanguage(event.target.value);
    dispatch(
      setLanguageAction({
        language: event.target.value,
      })
    );
  };

  const handleLogout = () => {
    setTimeout(() => {
      history.push(urls.homeViewPath);
      store.dispatch(logOutAction());
    }, 1000);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const getAstrologyLogo = () => {
    return (
      <Box sx={{ cursor: "pointer" }}>
        <a href={isAuthenticated ? urls.dashboardViewPath : urls.homeViewPath}>
          {isMobile ? (
            <img
              src={AstrologyLogo}
              alt="Module Not Found..."
              width={"130px"}
            />
          ) : (
            <img
              src={AstrologyLogo}
              alt="Module Not Found..."
              height={"80px"}
            />
          )}
        </a>
      </Box>
    );
  };

  const getMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        PaperProps={{
          elevation: 0,
          sx: classes.menuWrapper,
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {isAuthenticated && (
          <>
            {menuItems.map((menu: any, index: number) => {
              return (
                <>
                  <MenuItem onClick={menu.onclick} key={index}>
                    <ListItemIcon>
                      <img
                        src={menu.icon}
                        height={"20px"}
                        alt="Module Not Found..."
                      />
                    </ListItemIcon>
                    <Typography sx={classes.menuItemText}>
                      {menu.title}
                    </Typography>
                  </MenuItem>
                  {index + 1 !== menuItems.length && <Divider />}
                </>
              );
            })}
          </>
        )}

        {isTablet && (
          <>
            {isAuthenticated && (
              <MenuItem sx={{ pt: 3 }}>
                <Box sx={{ ...classes.moonDarkBox, py: 1 }}>
                  <Stack
                    direction={"row"}
                    spacing={1}
                    alignItems={"center"}
                    height={"100%"}
                  >
                    <img
                      src={getMoonPhasesSymbols(moonDetails.moonPhase)}
                      height={"40px"}
                      alt="Module Not Found..."
                    />
                    <Box>
                      <Typography
                        sx={{ ...classes.moonHeader, color: "#000000" }}
                      >
                        {t("vedicMoon")}
                      </Typography>
                      <Typography
                        sx={{ ...classes.moonDesc, color: "#000000" }}
                      >
                        {moonDetails.nakshatra}
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
              </MenuItem>
            )}

            <MenuItem sx={{ pt: 1 }}>
              <CustomSelect
                menuItems={menuList[1].menu}
                onChange={handleLanguageChange}
                value={selectedLanguage}
                name={"bloodGroup"}
                id={"bloodGroup"}
                customClasses={classes.darkTextField}
              />
            </MenuItem>
          </>
        )}
      </Menu>
    );
  };

  const getHeaderContent = () => {
    return (
      <Stack direction={"row"} spacing={2}>
        {doesPageHasComponent(strings.MOON) && (
          <Box sx={classes.vedicMoonBox} py={0.8}>
            <Stack
              direction={"row"}
              spacing={1}
              alignItems={"center"}
              height={"100%"}
            >
              {xl ? (
                <img
                  src={getMoonPhasesSymbols(moonDetails.moonPhase)}
                  height={"40px"}
                  alt="Module Not Found..."
                />
              ) : (
                <img
                  src={getMoonPhasesSymbols(moonDetails.moonPhase)}
                  height={"50px"}
                  alt="Module Not Found..."
                />
              )}
              <Box>
                <Typography
                  sx={{ ...classes.moonHeader, whiteSpace: "nowrap" }}
                  pb={0.5}
                >
                  {t("vedicMoon")}
                </Typography>
                <Typography sx={{ ...classes.moonDesc, whiteSpace: "nowrap" }}>
                  {moonDetails.nakshatra}
                </Typography>
              </Box>
            </Stack>
          </Box>
        )}
        <CustomSelect
          menuItems={menuList[0].header}
          onChange={handleLanguageChange}
          value={selectedLanguage}
          name={"bloodGroup"}
          id={"bloodGroup"}
          customClasses={classes.textField}
        />
        {doesPageHasComponent(strings.PROFILE) && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              ...classes.moonBox,
              px: 1,
            }}
          >
            <IconButton
              onClick={handleClick}
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              {xl ? (
                <img
                  src={profileIcon}
                  height={"35px"}
                  alt="Module Not Found..."
                />
              ) : (
                <img
                  src={profileIcon}
                  height={"40px"}
                  alt="Module Not Found..."
                />
              )}
            </IconButton>
          </Box>
        )}
      </Stack>
    );
  };

  const dialogHeaderContent = () => {
    return (
      <Stack direction={"column"} alignItems={"center"} spacing={2} pt={5}>
        <Typography sx={classes.modalHeader} px={2}>
          {user} , {t("logoutHeader")}
        </Typography>
        <Divider
          sx={{
            border: "1px solid #222222",
            width: "50%",
            height: "0px",
          }}
        />
      </Stack>
    );
  };

  const dialogBodyContent = () => {
    return (
      <Stack
        direction={"column"}
        alignItems={"center"}
        pb={5}
        spacing={2}
        sx={classes.dialogContent}
      >
        <Typography sx={classes.lightFont}>{t("logoutContent")}</Typography>
        <Stack direction={{ sm: "row", xs: "column" }} spacing={2}>
          <Button onClick={handleClose} sx={classes.cancelBtn}>
            <Box sx={classes.cancelBtnBox}>
              <Typography className="btnText">{t("cancel")}</Typography>
            </Box>
          </Button>
          <Button onClick={handleLogout} sx={classes.logoutBtn}>
            <Typography className="btnText">{t("logout")}</Typography>
          </Button>
        </Stack>
      </Stack>
    );
  };

  const getLogoutDialog = () => {
    return (
      <CustomDialog
        width="600px"
        borderRadius="10px"
        isDialogOpen={openDialog}
        closable={true}
        closeButtonVisibility={true}
        handleDialogClose={handleClose}
        dialogHeaderContent={dialogHeaderContent()}
        dialogBodyContent={dialogBodyContent()}
      />
    );
  };

  const getAppHeader = () => {
    return (
      <>
        <AppBar
          position="fixed"
          sx={{
            width: "100%",
            height: "120px",
            backgroundColor: "#070530",
            boxShadow: "0px 0px",
            alignItems: "center",
            [theme.breakpoints.down("sm")]: {
              height: "110px",
            },
          }}
        >
          <Toolbar
            sx={{
              "&.MuiToolbar-root": {
                px: 0,
                height: "120px",
                width: "90%",
              },
            }}
          >
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid item xl={4} lg={4} md={1} sm={0} xs={0}>
                {doesPageHasComponent(strings.BACK) && (
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    sx={{ cursor: "pointer", width: "fit-content" }}
                    onClick={() => history.goBack()}
                  >
                    {isMobile ? (
                      <img
                        src={backArrow}
                        height={"15px"}
                        alt="Module not found..."
                      />
                    ) : (
                      <img
                        src={backArrow}
                        height={"20px"}
                        alt="Module not found..."
                      />
                    )}

                    <Typography pl={2} sx={classes.backText}>
                      Back
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid
                item
                xl={4}
                lg={4}
                md={5}
                sm={6}
                xs={6}
                sx={{ ...centerItemFlex }}
              >
                {getAstrologyLogo()}
              </Grid>
              <Grid
                item
                xl={4}
                lg={4}
                md={5}
                sm={1}
                xs={1}
                sx={{ display: "flex", justifyContent: "end" }}
              >
                {!isTablet && getHeaderContent()}
                {isTablet && (
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <MenuIcon sx={{ pt: 2, color: "#FFFFFF" }} />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Toolbar>
          <Box
            sx={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            {/* <Typography variant="body1">
              <img src={leftArrow} alt="Module not found..." />
            </Typography> */}
            <Divider sx={classes.dividerStyle} />
            {/* <Typography variant="body1">
              <img src={rightArrow} alt="Module not found..." />
            </Typography> */}
          </Box>
          {getMenu()}
          {getLogoutDialog()}
        </AppBar>
      </>
    );
  };

  return getAppHeader();
};

export default AppHeader;
