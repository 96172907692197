class BaseURL {
  PROD = false;

  url_prod = "https://api.vedi.love/astrology/prod/api/v3";
  url_dev = "https://api.vedi.love/uat/api";
//   url_dev = "http://192.168.50.31:5000/uat/api";
  url = this.PROD ? this.url_prod : this.url_dev;

  PROD_STRIP_KEY =
    "pk_live_51OL07UEGG5xXlgXr9GrQGv7qY212gysHUfYKUIScNWc6b3Ro6eKQv9EfWA1Y3Kyo6gxyZASJSSS0tqTZ40Qzn83B00mQRwY2u9";
  // UAT_STRIP_KEY =
  //   "pk_live_51OL07UEGG5xXlgXr9GrQGv7qY212gysHUfYKUIScNWc6b3Ro6eKQv9EfWA1Y3Kyo6gxyZASJSSS0tqTZ40Qzn83B00mQRwY2u9";
  // UAT
  UAT_STRIP_KEY =
    "pk_test_51OL07UEGG5xXlgXrkQr0CASAMcG8FpMQxSW5yrhCMRA8oNSMEC1iZoM3JaRJsaFuji87bzvMlNNZhO6BAdx6buuU00FKQdoPR4";
  // local
//   UAT_STRIP_KEY =
//     "pk_test_51OFzLeDmrT0JxCctwatD2fFLwI6Yv0Rcgt02ZquTQl7ylbsbDYisCJCGdbWuoS6JkLGkk1VawxugqLt7ENbY3A9x00ombnumwx";

  STRIPE_PUBLIC_KEY = this.PROD ? this.PROD_STRIP_KEY : this.UAT_STRIP_KEY;
}

let baseUrl = new BaseURL();
export default baseUrl;
