import {
  centerItemFlex,
  getRelativeFontSize,
  mediumFont,
  regularFont,
  theme,
} from "../../utils/styles";

const dashboardStyles = {
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    mx: 5,
    [theme.breakpoints.down("sm")]: {
      mx: 2,
    },
  },
  mainBox: {
    p: 1,
    borderRadius: "20px",
    minHeight: "calc(100vh - 220px)",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    background: "rgba(255, 255, 255, 0.07)",
    backdropFilter: "blur(12px)",
    border: "1px solid rgba(255, 255, 255, 0.14)",
    marginTop: "25px",
    maxWidth: "1750px",
    width: "100%",
    // [theme.breakpoints.down("sm")]: {
    //   height: "calc(100vh - 222px)",
    // },
    // "@supports (-webkit-touch-callout: none)": {
    //   height: "calc(100vh - 252px)",
    // },
  },
  subBox: {
    p: 3,
    minHeight: "calc(100vh - 260px)",
    borderRadius: "20px",
    border: "1px solid rgba(255, 255, 255, 0.14)",
    // overflowY: "auto",
    // overflowX: "hidden",
    // "&::-webkit-scrollbar": {
    //   width: "5px",
    // },
    // "&::-webkit-scrollbar-thumb": {
    //   borderRadius: "10px",
    //   border: "1px solid #FFFFFF",
    //   background: "#282945",
    // },
    // [theme.breakpoints.down("sm")]: {
    //   height: "calc(100vh - 240px)",
    //   p: 1,
    // },
    // "@supports (-webkit-touch-callout: none)": {
    //   height: "calc(100vh - 270px)",
    // },
  },
  promoCard: {
    minWidth: "200px",
    height: "50px",
    display: "flex",
    justifyContent: "center",
    backdropFilter: "blur(5.5px)",
    background: "transparent",
    borderRadius: "10px",
    backgroundClip: "content-box,border-box",
    backgroundImage:
      "linear-gradient(#9053f6,#3b1f5b),linear-gradient(90deg,#DF66E3 -0.11%,#5E75FF 55.68%, #2CF1DF 100.01%)",
    backgroundOrigin: "border-box",
    border: "double 2px transparent",
    cursor: "pointer",
  },
  italicTypo: {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "jejuhallasan-regular",
    fontSize: "77px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "0.77px",
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      fontSize: "50px",
    },
  },
  welcomeText: {
    margin: "0 25%",
    ...regularFont,
    color: "#C2C2C2",
    fontSize: getRelativeFontSize(6),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
    [theme.breakpoints.down("lg")]: {
      margin: "0 5%",
    },
    [theme.breakpoints.down("xl")]: {
      margin: "0 5%",
    },
  },
  profileCard: {
    minWidth: "200px",
    height: "150px",
    display: "flex",
    justifyContent: "center",
    backdropFilter: "blur(5.5px)",
    background: "#9053F6",
    borderRadius: "20px",
    border: "1px solid rgba(255, 255, 255, 0.37)",
    cursor: "pointer",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      height: "120px",
    },
  },
  profileName: {
    ...mediumFont,
    color: "#ffffff",
    fontSize: getRelativeFontSize(6),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  profileDOB: {
    ...mediumFont,
    color: "rgba(255, 255, 255, 0.43)",
    fontSize: getRelativeFontSize(6),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  cardStyle: {
    minWidth: "200px",
    height: "150px",
    display: "flex",
    justifyContent: "center",
    backdropFilter: "blur(5.5px)",
    background: "transparent",
    borderRadius: "20px",
    backgroundClip: "content-box,border-box",
    backgroundImage:
      "linear-gradient(#424451,#321751),linear-gradient(90deg,#DF66E3 -0.11%,#5E75FF 55.68%, #2CF1DF 100.01%)",
    backgroundOrigin: "border-box",
    border: "double 2px transparent",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      height: "120px",
    },
  },
  headerText: {
    ...mediumFont,
    color: "#FFFFFF",
    fontSize: getRelativeFontSize(6),
    pl: 1,
    alignSelf: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  gradientTypo: {
    background:
      "linear-gradient(90deg, #FC72FF 0%, #8F68FF 25%, #487BFF 50%, #2CD9FF 75%, #2CFFCC 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    ...mediumFont,
    fontSize: getRelativeFontSize(7),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(3),
    },
  },
  loginBtn: {
    py: 1.5,
    borderRadius: "8px",
    background: "#9053F6",
    minWidth: "180px",
    textShadow: "0 1px 0 rgb(0 0 0 / 40%)",
    transition: "box-shadow 0.15s ease, transform 0.15s ease",
    willChange: "box-shadow,transform",

    "&:hover": {
      boxShadow:
        "0px 0.1em 0.2em rgb(45 35 66 / 40%), 0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -3px 0px #FDFDFD",
      transform: "translateY(-0.1em)",
      background: "#9053F6",
      color: "#FDFDFD",
    },
    "&:disabled": {
      background: "#c09aff",
    },
  },
  loginBtnTypo: {
    color: "#FDFDFD",
    ...mediumFont,
    fontSize: getRelativeFontSize(8),
  },
  promoTextField: {
    width: "100%",
    borderRadius: "8px",
    border: "none",
    background: "#F0F0F0",
    backdropFilter: "blur(5.5px)",

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
    },
    "& .MuiOutlinedInput-notchedOutline:hover": {
      borderColor: "#F0F0F0",
    },

    "& .MuiInputBase-input": {
      position: "relative",
      padding: "8px 8px",
      color: "#9053F6",
      textAlign: "center",
      fontSize: getRelativeFontSize(12),
      ...mediumFont,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      borderColor: "#F0F0F0",
      "&.Mui-focused fieldset": {
        borderColor: "#F0F0F0",
      },
    },
  },
  lightTypo: {
    ...regularFont,
    fontSize: getRelativeFontSize(1),
    color: "rgba(34, 34, 34, 0.70)",
  },
  contactUsHeader: {
    ...regularFont,
    fontSize: getRelativeFontSize(16),
    color: "#222222",
    textAlign: "center",
  },
  errorStyling: {
    ...regularFont,
    fontSize: getRelativeFontSize(1),
    paddingLeft: "10px",
  },
  textField: {
    width: "99%",
    background: "transparent",
    borderRadius: "10px",
    backgroundClip: "content-box,border-box",
    backgroundImage:
      "linear-gradient(#ffffff,#ffffff),linear-gradient(90deg,#DF66E3 -0.11%,#5E75FF 55.68%, #2CF1DF 100.01%)",
    backgroundOrigin: "border-box",
    border: "double 2px transparent",

    boxShadow: "none",
    ".MuiOutlinedInput-notchedOutline": { border: 0 },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },

    "& .MuiSelect-outlined": {
      border: "none",
      position: "relative",
      padding: "12px 12px",
      color: "#000000",

      "&::placeholder": {
        ...mediumFont,
        color: "##000000de",
      },
    },
    "& .MuiSelect-icon": {
      color: "#000000",
    },
    "& ..MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      borderColor: "#ffffff",
      "&.Mui-focused fieldset": {
        borderColor: "#ffffff",
      },
    },
  },
  icon: {
    color: "#ffffff",
  },
  subscriptionInfoText: {
    ...mediumFont,
    color: "#222222",
    fontSize: getRelativeFontSize(6),
    textAlign: "center",
  },
  thankYouText: {
    ...mediumFont,
    color: "#9053F6",
    fontSize: getRelativeFontSize(20),
    textAlign: "center",
  },
  subscriptionBox: {
    borderRadius: "8px",
    background: "rgba(144, 83, 246, 0.28)",
    backdropFilter: "blur(5.5px)",
    width: "100%",
    padding: "10px",
  },
  subscriptionText: {
    ...mediumFont,
    color: "#9053F6",
    fontSize: getRelativeFontSize(5),
    textAlign: "center",
  },
  wrapper: {
    height: "155px",
    [theme.breakpoints.down("sm")]: {
      height: "125px",
    },
  },
  defaultProfileBox: {
    position: "relative",
    top: "48px",
    left: "1px",
    zIndex: 1,
    width: 0,
  },
  dialogContent: {
    margin: "0 5%",
    [theme.breakpoints.down("sm")]: {
      margin: "0 0%",
    },
  },
  centerFlex: {
    ...centerItemFlex,
  },
} as const;

export default dashboardStyles;
