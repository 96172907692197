import urls from "../../../../global/constants/UrlConstants";
import { getCallParams, makeCall } from "../../../../utils/service";

export const deletePaymentMethod = async (paymentMethodId: string) => {
  try {
    const callParams = await getCallParams("DELETE", { paymentMethodId });
    const response = await makeCall(urls.deletePaymentMethod, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
