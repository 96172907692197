import urls from "../../../global/constants/UrlConstants";
import { getCallParams, makeCall } from "../../../utils/service";

export const getMoonPhases = async (language: string) => {
  const url = `${urls.getMoonPhases}?&lang=${language}`;
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
