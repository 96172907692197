import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import analysisStyles from "../../Analysis.styles";
import { useState, useEffect } from "react";
import { handleErrorMessage } from "../../../../helpers/methods";
import { getInnerDashaData } from "../../AnalysisService";
import notifiers from "../../../../global/constants/NotificationConstants";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { CustomIcon } from "../../../../global/components";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useTranslation } from "react-i18next";
import { getMahadashaData } from "../../../Dashboard/DashboardService";
import MahadashaChartComponent from "./MahadashaChartComponent";
import { boldFont, theme } from "../../../../utils/styles";

interface CustomProps {
  profileId: string;
  setIsLoading: Function;
}

const Mahadasha = (props: CustomProps) => {
  const classes = analysisStyles;
  const { i18n } = useTranslation();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [currentData, setCurrentData] = useState([]);
  const [colorPercentage, setColorPercentage] = useState<any[]>([]);
  const [dashaDetails, setDashaDetails] = useState({
    dashaName: "",
    dashaTagline: "",
    endDate: "",
    pranadashaPlanet: "",
    startDate: "",
  });
  const [dashaData, setDashaData] = useState<any[]>([]);
  const [colorTagline, setColorTagline] = useState<any[]>([]);

  const planetColorMapping: any = {
    Ketu: "white",
    Venus: "#22cb22",
    Sun: "#22cb22",
    Moon: "#22cb22",
    Mars: "red",
    Rahu: "white",
    Jupiter: "#22cb22",
    Saturn: "red",
    Mercury: "#22cb22",
  };

  const colorNameMapping: any = {
    red: "Malefic",
    green: "Benefic",
    white: "Neutral",
  };

  useEffect(() => {
    getMahadashaDetails();
  }, [i18n.language]);

  const getMahadashaDetails = async () => {
    try {
      props.setIsLoading(true);
      const language = i18n.language.toLowerCase();
      const response = await getMahadashaData(props?.profileId!, language);
      setCurrentData(response.dasha);
      props.setIsLoading(false);
    } catch (error: any) {
      props.setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const getInnerDasha = async (
    endDate: string,
    planetName: string,
    startDate: string
  ) => {
    try {
      const obj = {
        endDate,
        planetName,
        startDate,
      };
      setDashaData([...dashaData, obj]);
      props.setIsLoading(true);
      const response = await getInnerDashaData(obj);
      props.setIsLoading(false);
      setCurrentData(response.dasha);
      setDashaDetails({
        dashaName: response.dashaName,
        dashaTagline: response.dashaTagline,
        endDate: response.enddate ?? "",
        pranadashaPlanet: response.pranadashaPlanet ?? "",
        startDate: response.startdate ?? "",
      });
      setColorTagline(response.colorTagline);
      const inputArray = Object.entries(response.percentage);
      const totalPercentage = inputArray.reduce(
        (acc, [, percentage]: any) => acc + percentage,
        0
      );
      const outputArray: any[] = inputArray.map(([color, percentage]: any) => ({
        color: colorNameMapping[color],
        percentage: (percentage / totalPercentage) * 100,
      }));
      setColorPercentage(outputArray);
    } catch (error: any) {
      props.setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const handleBack = (dashaName: string) => {
    if (dashaName === "Antar Dasha") {
      getMahadashaDetails();
      setDashaData([]);
      setDashaDetails({
        dashaName: "",
        dashaTagline: "",
        endDate: "",
        pranadashaPlanet: "",
        startDate: "",
      });
    } else {
      dashaData.pop();
      const obj = dashaData.pop();
      getInnerDasha(obj.endDate, obj.planetName, obj.startDate);
    }
  };

  const getMahadasha = () => {
    return (
      <Grid container pl={{ md: 3, xs: 0 }} py={3} sx={{ rowGap: "15px" }}>
        {dashaDetails.dashaName && (
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={classes.mahadashaHeader}
          >
            <Box
              sx={{
                paddingBottom: "45px",
                cursor: "pointer",
              }}
              onClick={() => handleBack(dashaDetails.dashaName)}
            >
              <KeyboardBackspaceIcon
                htmlColor="#FFFFFF"
                fontSize={isTablet ? "medium" : "large"}
              />
            </Box>
            <Stack direction={"column"} pl={{ md: 2, xs: 0.5 }}>
              <Typography sx={classes.dashaNameTypo} pb={1.5}>
                {dashaDetails.dashaName}
              </Typography>
              <Grid
                container
                sx={{
                  borderTop: "2px solid #ffffff73",
                  pt: 1.5,
                }}
              >
                {colorTagline.map((tagline: any, index: number) => {
                  return (
                    <Grid item display={"flex"} alignItems={"center"}>
                      <Typography
                        pl={0.5}
                        sx={{
                          ...classes.dashaTagline,
                          color: planetColorMapping[tagline.planetName ?? ""],
                        }}
                      >
                        {tagline.planetName}
                      </Typography>
                      <Typography
                        px={0.5}
                        sx={{
                          ...classes.dateTypo,
                          width: "fit-content",
                        }}
                      >
                        ({tagline.dasha})
                      </Typography>
                      {index < colorTagline.length - 1 && (
                        <Typography
                          px={0.5}
                          sx={{
                            ...classes.dateTypo,
                            width: "fit-content",
                          }}
                        >
                          -
                        </Typography>
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            </Stack>
            {!isTablet && (
              <Box>
                <MahadashaChartComponent data={colorPercentage} />
              </Box>
            )}
          </Grid>
        )}

        {dashaDetails.dashaName && isTablet && (
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={classes.mahadashaHeader}
            justifyContent={"center"}
          >
            <Box>
              <MahadashaChartComponent data={colorPercentage} />
            </Box>
          </Grid>
        )}

        {dashaDetails.pranadashaPlanet && (
          <>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ ...classes.mahadashaContent, cursor: "auto" }}
            >
              <Stack direction={"row"} spacing={1}>
                <Typography sx={classes.dateTypo}>
                  {dashaDetails.startDate}
                </Typography>
                <CustomIcon icon={<HorizontalRuleIcon htmlColor="#9F3AFF" />} />
                <Typography sx={classes.dateTypo}>
                  {dashaDetails.endDate}
                </Typography>
              </Stack>
            </Grid>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ ...classes.mahadashaContent, cursor: "auto" }}
            >
              <Stack direction={"column"} pl={2}>
                <Typography
                  sx={{
                    ...classes.planetNameTypo,
                    color:
                      planetColorMapping[dashaDetails.pranadashaPlanet ?? ""],
                    ...boldFont,
                  }}
                  pb={1.5}
                >
                  {dashaDetails.pranadashaPlanet}
                </Typography>

                <Stack
                  direction={"row"}
                  spacing={1}
                  pt={1.5}
                  sx={{
                    width: "fit-content",
                    borderTop: "2px solid #ffffff73",
                  }}
                >
                  <Typography sx={classes.dateTypo}>
                    {dashaDetails.startDate}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          </>
        )}

        {currentData?.map((data: any) => {
          return (
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={
                dashaData.length < 4
                  ? classes.mahadashaContent
                  : { ...classes.mahadashaContent, cursor: "auto" }
              }
              onClick={() => {
                dashaData.length < 4 &&
                  getInnerDasha(data.endDate, data.planetName, data.startDate);
              }}
            >
              <Stack direction={"column"} pl={2}>
                <Typography
                  sx={
                    dashaData.length === 4
                      ? {
                          ...classes.planetNameTypo,
                          color: planetColorMapping[data.planetName ?? ""],
                          ...boldFont,
                        }
                      : classes.planetNameTypo
                  }
                  pb={1.5}
                >
                  {data.planetName}
                </Typography>

                <Stack
                  direction={"row"}
                  spacing={1}
                  pt={1.5}
                  sx={{
                    width: "fit-content",
                    borderTop: "2px solid #ffffff73",
                  }}
                >
                  <Typography sx={classes.dateTypo}>
                    {data.startDate}
                  </Typography>
                  <CustomIcon
                    icon={<HorizontalRuleIcon htmlColor="#9F3AFF" />}
                  />
                  <Typography sx={classes.dateTypo}>{data.endDate}</Typography>
                </Stack>
              </Stack>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return getMahadasha();
};

export default Mahadasha;
