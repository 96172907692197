import baseUrl from "./BaseURL";
import { StringConstants } from "./StringConstants";

class UrlConstants extends StringConstants {
  url = baseUrl.url;

  timeout = "timeout";

  // view-path
  homeViewPath = "/";
  loginViewPath = "/login";
  registerViewPath = "/register";
  resetPasswordViewPath = "/reset-password";
  setPasswordViewPath = "/set-password";
  dashboardViewPath = "/dashboard";
  profileViewPath = "/profile";
  calculateViewPath = "/calc";
  analysisViewPath = "/analysis";
  pricingViewPath = "/pricing";
  paymentViewPath = "/payment";
  userProfileViewPath = "/my-profile";
  updatePasswordViewPath = "/update-password";
  subscriptionViewPath = "/subscription";
  successViewPath = "/success";
  failViewPath = "/fail";
  deleteAccountViewPath = "/delete-account";
  privacyPolicyViewPath = "/privacy-policy";

  // apis

  //auth
  loginUser = `${this.url}/login`;
  registerUser = `${this.url}/sign-up`;
  verifyOtp = `${this.url}/verify-otp`;
  resendOtp = `${this.url}/resend-otp`;
  setPassword = `${this.url}/set-password`;
  resetPassword = `${this.url}/reset-password`;
  updatePassword = `${this.url}/update-password`;
  deleteAccount = `${this.url}/delete-user`;

  //profile
  getProfile = `${this.url}/get-profiles`;
  getProfileById = `${this.url}/get-profile-id`;
  addProfile = `${this.url}/add-profile`;
  updateProfile = `${this.url}/update-profile`;
  makeDefault = `${this.url}/make-default-profile`;

  //user-profile
  getUserProfile = `${this.url}/get-user-profile`;
  updateUserProfile = `${this.url}/update-user-profile`;

  //subscription
  getPlans = `${this.url}/get-plans`;
  getPayPalUrl = `${this.url}/paypal-checkout`;
  updatePlan = `${this.url}/change-plan`;
  getSubscription = `${this.url}/get-subscription`;
  createSubscription = `${this.url}/create-subscription`;
  cancelSubscription = `${this.url}/cancel-subscription`;
  getInvoices = `${this.url}/get-invoices`;
  setPromoCode = `${this.url}/activate-promocode`;
  getSuccessDetails = `${this.url}/success-details`;

  //payment-methods
  getPaymentMethods = `${this.url}/get-payment-methods`;
  addPaymentMethods = `${this.url}/create-payment-method`;
  makeDefaultPaymentMethod = `${this.url}/make-default-payment-method`;
  deletePaymentMethod = `${this.url}/delete-payment-method`;

  //analysis
  analysisData = `${this.url}/get-analysis-data`;
  missingMinerals = `${this.url}/get-missing-minerals`;
  mahadashaData = `${this.url}/get-mahadasha`;
  innerDashaCosmicData = `${this.url}/get-innerdasha-cosmic`;
  buyNowUrl = `${this.url}/get_web_url`;

  getMoonPhases = `${this.url}/get-moon-phase`;
  contactUsApi = `${this.url}/contact-us`;
}

let urls = new UrlConstants();
export default urls;
