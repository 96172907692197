import {
  useMediaQuery,
  Box,
  Grid,
  Container,
  Typography,
  Stack,
  Button,
} from "@mui/material";
import { LightTooltip, theme } from "../../utils/styles";
import homeStyles from "./Home.styles";
import { useAppSelector } from "../../utils/hooks";
import { selectAuthenticated } from "../../redux/authSlice";
import { Location } from "history";
import history from "../../utils/history";
import urls from "../../global/constants/UrlConstants";
import AppHeader from "../Shared/AppHeader/AppHeader";
import { useState } from "react";
import analysisIcon from "../../assets/icons/analysisIcon.svg";
import missingMineralsIcon from "../../assets/icons/missingMineralsIcon.svg";
import mahadashaIcon from "../../assets/icons/mahadashaIcon.svg";
import { useTranslation } from "react-i18next";
import strings from "../../global/constants/StringConstants";
import homeImage from "../../assets/images/homeImage.png";
import CookieConsent, { Cookies } from "react-cookie-consent";
import CookieDialog from "../Shared/Dialogs/CookieDialog/CookieDialog";

interface CustomProps {
  location?: Location;
}

const Home = (props: CustomProps) => {
  const classes = homeStyles;
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isAuthenticated = useAppSelector(selectAuthenticated);
  const { t } = useTranslation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [open, setOpen] = useState(true);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getYear = () => {
    return new Date().getFullYear();
  };

  const getFooter = () => {
    return (
      <Box sx={classes.footer}>
        <Typography sx={classes.footerTypo}>
          &copy; {getYear()} {t("footer")} | {t("email")}: info@xiegawodnika.com
          |
        </Typography>
        <Typography
          pl={0.5}
          sx={{ ...classes.footerTypo, cursor: "pointer" }}
          onClick={() => history.push(urls.privacyPolicyViewPath)}
        >
          {t("privacyPolicyFooter")}
        </Typography>
      </Box>
    );
  };

  const getContent = () => {
    return (
      <Box sx={classes.content}>
        <Box sx={classes.mainContainer}>
          <Box sx={classes.mainBox}>
            <Box sx={classes.subBox}>
              <Stack
                direction={"column"}
                justifyContent={"space-between"}
                sx={classes.contentWrapper}
              >
                <Stack direction={"column"}>
                  <Container maxWidth="xl">
                    <Grid container alignItems={"center"}>
                      <Grid
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        px={{ md: 2, xs: 0 }}
                        sx={classes.center}
                      >
                        <img
                          src={homeImage}
                          width={"100%"}
                          style={{ maxWidth: "550px" }}
                          alt="Module Note Found..."
                        />
                      </Grid>
                      <Grid
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        px={{ md: 2, xs: 0 }}
                        sx={classes.center}
                      >
                        <Stack direction={"column"} pt={{ md: 0, xs: 3 }}>
                          <Typography sx={classes.italicTypo}>
                            {t("homeHeader")}
                          </Typography>
                          <Typography sx={classes.welcomeText} pt={4}>
                            {t("homeContent")}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Container>
                  <Container maxWidth="lg">
                    <Grid container pt={{ sm: 7, xs: 4 }}>
                      <Grid item xl={4} lg={4} md={6} sm={12} xs={12} p={1}>
                        <LightTooltip
                          placement="top"
                          arrow
                          sx={{
                            "& .MuiTooltip-tooltip": {
                              backgroundColor: "#ffffff",
                              fontSize: "16px",
                              color: "#000000",
                            },
                          }}
                          title={t("analysisTooltip")}
                        >
                          <Box sx={classes.cardStyle}>
                            <Box py={2} display={"flex"}>
                              <img
                                src={analysisIcon}
                                alt="Module not found..."
                                style={{ width: "auto" }}
                              />
                              <Typography sx={classes.headerText}>
                                {t("analysisHeader")}
                              </Typography>
                            </Box>
                          </Box>
                        </LightTooltip>
                      </Grid>
                      <Grid item xl={4} lg={4} md={6} sm={12} xs={12} p={1}>
                        <LightTooltip
                          placement="top"
                          arrow
                          sx={{
                            "& .MuiTooltip-tooltip": {
                              backgroundColor: "#ffffff",
                              fontSize: "16px",
                              color: "#000000",
                            },
                          }}
                          title={t("mineralsTooltip")}
                        >
                          <Box sx={classes.cardStyle}>
                            <Box py={2} display={"flex"}>
                              <img
                                src={missingMineralsIcon}
                                alt="Module not found..."
                                style={{ width: "auto" }}
                              />
                              <Typography sx={classes.headerText}>
                                {t("mineralsHeader")}
                              </Typography>
                            </Box>
                          </Box>
                        </LightTooltip>
                      </Grid>
                      <Grid item xl={4} lg={4} md={6} sm={12} xs={12} p={1}>
                        <LightTooltip
                          placement="top"
                          arrow
                          sx={{
                            "& .MuiTooltip-tooltip": {
                              backgroundColor: "#ffffff",
                              fontSize: "16px",
                              color: "#000000",
                            },
                          }}
                          title={t("mahadashaTooltip")}
                        >
                          <Box sx={classes.cardStyle}>
                            <Box py={2} display={"flex"}>
                              <img
                                src={mahadashaIcon}
                                alt="Module not found..."
                                style={{ width: "auto" }}
                              />
                              <Typography sx={classes.headerText}>
                                {t("mahadashaHeader")}
                              </Typography>
                            </Box>
                          </Box>
                        </LightTooltip>
                      </Grid>
                    </Grid>
                    <Stack
                      direction={{ md: "row", xs: "column" }}
                      justifyContent={"center"}
                      spacing={2}
                      pt={{ sm: 7, xs: 4 }}
                      pb={5}
                    >
                      <Button
                        sx={classes.signupBtn}
                        onClick={() => {
                          history.push(urls.registerViewPath);
                        }}
                      >
                        {t("signup")}
                      </Button>
                      <Button
                        sx={classes.loginBtn}
                        onClick={() => {
                          history.push(urls.loginViewPath);
                        }}
                      >
                        {t("login")}
                      </Button>
                    </Stack>
                  </Container>
                </Stack>
                <Container maxWidth="lg">
                  <Box sx={classes.instructionBox}>
                    <Typography sx={classes.instructions}>
                      {t("userGuild")}
                    </Typography>
                  </Box>
                </Container>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const getHome = () => {
    return (
      <>
        <AppHeader toggleDrawer={toggleDrawer}></AppHeader>
        <Box>{getContent()}</Box>
        {/* <CookieDialog handleClose={handleClose} open={open} /> */}
        <Box py={1.5}>{getFooter()}</Box>
      </>
    );
  };

  if (isAuthenticated) {
    if (
      props.location?.pathname?.split("/")[1].toLowerCase() !==
      strings.RESET_PASSWORD
    ) {
      history.push(urls.dashboardViewPath);
      return null;
    }
  }
  return getHome();
};

export default Home;
